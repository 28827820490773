import smoothscroll from 'smoothscroll-polyfill';
import 'promise-polyfill/dist/polyfill.min';
import 'formdata-polyfill';

require('formdata-polyfill');

import './front-page.js';
import './templates/content-full-slider.js';
import './templates/courses/courses-item-video.js';

// NodeList.prototype.forEach polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}