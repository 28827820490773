import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import Misc from './base/misc.js';
import 'web-animations-js';

class HeaderMenu {
  constructor() {
    this.headerMenu = document.querySelector('.header-menu');
    this.mobileMenuSize = Misc.getMediaQueries()['mobilemenu-layout'];
    this.trigger = document.querySelector('.header-trigger--item');
    this.menuBG = document.querySelector('.header-menu--bg');
    this.subMenuCloseTrigger = document.querySelector('.header-sub-menu--close');
    this.subMenuBackTrigger = document.querySelector('.header-sub-menu--back');
    
    // Methods
    this.onTriggerClick = this.onTriggerClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleMobileMenu = this.handleMobileMenu.bind(this);
    this.openSubMenu = this.openSubMenu.bind(this);
    this.closeSubMenu = this.closeSubMenu.bind(this);
    this.closeAllSubMenus = this.closeAllSubMenus.bind(this);
    this.openSubMenuNonMobile = this.openSubMenuNonMobile.bind(this);

    // Add Icon Menu trigger 'click' event
    this.trigger.addEventListener('click', this.onTriggerClick);

    // Handle nonMobile menu
    this.handleNonMobileMenu();

    // Handle Mobile menu
    window.addEventListener('resize', this.handleMobileMenu);
    if (Misc.getViewport().width <= this.mobileMenuSize) {
      this.handleMobileMenu();
    }
}

  onTriggerClick(event) {
    event.preventDefault()

    // Open menu
    if (!document.body.classList.contains('header-menu--on')) {
      this.openMenu();
    } else {
      this.closeMenu();
    }
  }

  openMenu() {
    document.body.classList.add('header-menu--on');
    disableBodyScroll(this.headerMenu);
    // this.headerMenu.style.overflow = 'scroll';
    // this.headerMenu.style.height = '700px';
    this.menuBG.addEventListener('click', this.handleOutsideClick );
  }

  closeMenu() {
    document.body.classList.remove('header-menu--on');
    this.menuBG.removeEventListener('click', this.handleOutsideClick );
    enableBodyScroll(this.headerMenu);
  }

  handleOutsideClick(event) {
    this.closeMenu();
    this.closeAllSubMenus();
  }

  handleMobileMenu() {
    if (Misc.getViewport().width <= this.mobileMenuSize) {
      document.querySelectorAll('.header-menu .menu-item-has-children > a').forEach(item => {
        item.addEventListener('click', this.openSubMenu);
      });
    } else {
      document.querySelectorAll('.header-menu li.menu-item-has-children > a').forEach(item => {
        item.removeEventListener('click', this.openSubMenu);
        item.addEventListener('click', this.openSubMenuNonMobile);
      });

      if (document.querySelector('.sub-menu--open')) {
        document.querySelector('.sub-menu--open').classList.remove('sub-menu--open');
      }
    }
  }

  openSubMenu(event) {
    event.preventDefault();
    const subMenuItem = event.currentTarget.parentNode.querySelector('.sub-menu');

    subMenuItem.classList.add('sub-menu--open');
    this.subMenuCloseTrigger.addEventListener('click', this.closeSubMenu);
    this.subMenuBackTrigger.addEventListener('click', this.closeSubMenu);
    this.subMenuCloseTrigger.style.display = '';
    this.subMenuBackTrigger.style.display = '';
    this.trigger.style.display = 'none';
  }

  closeSubMenu(event) {
    const subMenuItem = document.querySelector('.sub-menu--open');
    const animationKeyFrames = {
      transform: ['translateX(0)', 'translateX(-100%)'],
    };
    
    const targetAnimation = subMenuItem.animate(animationKeyFrames, {
      duration: 200,
      fill: 'forwards',
      easing: 'ease-in-out'
    });

    this.subMenuCloseTrigger.style.display = 'none';
    this.subMenuBackTrigger.style.display = 'none';

    targetAnimation.onfinish = () => {
      subMenuItem.classList.remove('sub-menu--open');
      this.subMenuCloseTrigger.removeEventListener('click', this.closeSubMenu);
      targetAnimation.cancel();
      this.trigger.style.display = '';
    }


  }

  closeAllSubMenus() {
    
    if (document.querySelector('.menu-item-has-children > a.active')) {
      document.querySelector('.menu-item-has-children > a.active').classList.remove('active');
    }

    document.querySelectorAll('.menu-item-has-children > a').forEach(itemCleared => {
      const subMenu = itemCleared.parentNode.querySelector('.sub-menu');
      if (subMenu.classList.contains('sub-menu--open'))
        subMenu.classList.remove('sub-menu--open');
    });
  }

  handleNonMobileMenu() {
    document.querySelectorAll('.menu-item-has-children > a').forEach( item => {
      if (Misc.getViewport().width > this.mobileMenuSize) {
        item.addEventListener('click', this.openSubMenuNonMobile);
      }
    })
  }

  openSubMenuNonMobile(event) {
    event.preventDefault();
    this.closeAllSubMenus();
    event.currentTarget.classList.add('active');
    event.currentTarget.parentNode.querySelector('.sub-menu').classList.add('sub-menu--open');
  }



}

new HeaderMenu();
