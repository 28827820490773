exports = module.exports = mediaQueries;

// hd-layout     = 1180px;
// desktop-layout  = 960px;
// tablet-layout   = 641px;
// mobile-layout   = 640px;

function mediaQueries() {
  return {
    'mobilemenu-layout': 640,
    'bigdesktop-layout': 1180,
    'desktop-layout': 960,
    'tablet-layout': 641,
    'mobile-layout': 640
  }
};