import videojs from 'video.js';
import Misc from '../../base/misc';
import { VideoPlayer } from '../../video-js-player';

class CoursesItemVideo {
  constructor() {
    
    document.querySelectorAll('.block-courses').forEach( item => {
      if(item.querySelector('video')) {
        const videoID = item.querySelector('video').getAttribute('id');
        
        var videoElement = videojs(videoID, {
          controls: false,
          loop: true,
          preload: 'none',
        });
        
        new VideoPlayer({
          item: item, 
          video: videoElement 
        });

      }
    });

  }

}

Misc.onDocumentReady( () => {
  new CoursesItemVideo();
});