// core version + navigation, pagination modules:
import Swiper from 'swiper';
import SwiperCore, { Navigation, EffectFade, Autoplay } from 'swiper/core';

// configure Swiper to use modules
SwiperCore.use([Navigation, EffectFade, Autoplay]);

const contentSliderSwiper = new Swiper('.page-full-slider .swiper-container', {
  direction: 'horizontal',
  loop: true,
  autoplay: {
    delay: 5000,
  },
  autoHeight: true, //enable auto height
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  preloadImages: false,
  slidesPerView: 1,
  spaceBetween: 0,
  navigation: {
    nextEl: '.swiper-full-slider--button-next',
    prevEl: '.swiper-full-slider--button-prev',
  },
});