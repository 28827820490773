// core version + navigation, pagination modules:
import './weather';
import Swiper from 'swiper';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';

// configure Swiper to use modules
SwiperCore.use([Pagination, Navigation, Autoplay]);


// document.addEventListener('DOMContentLoaded', function(){

const swiperSponsors = new Swiper('.swiper-sponsors .swiper-container', {
  direction: 'horizontal',
  autoplay: {
    delay: 5000,
  },
  loop: true,
  preloadImages: false,
  slidesPerView: 6,
  spaceBetween: 50,
  // autoHeight: true,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 2,
      spaceBetween: 20
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 3,
      spaceBetween: 30
    },
    // when window width is >= 640px
    720: {
      slidesPerView: 4,
      spaceBetween: 30
    },
    960: {
      slidesPerView: 6,
      spaceBetween: 50
    }
  }
});

const swiperCourses = new Swiper('.swiper-courses .swiper-container', {
  direction: 'horizontal',
  // loop: true,
  preloadImages: false,
  slidesPerView: 3,
  // freeMode: true,
  spaceBetween: 50,
  navigation: {
    nextEl: '.swiper-courses--button-next',
    prevEl: '.swiper-courses--button-prev',
  },
  breakpoints: {
    // when window width is >= 320px
    420: {
      slidesPerView: 1,
      spaceBetween: 20
    },
    // when window width is >= 480px
    640: {
      slidesPerView: 2,
      spaceBetween: 30
    },
    // when window width is >= 640px
    720: {
      slidesPerView: 2,
      spaceBetween: 30
    },
    1440: {
      slidesPerView: 4,
      spaceBetween: 50
    }
  }
});