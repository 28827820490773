import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import 'web-animations-js';
import Misc from './base/misc';
const axios = require('axios').default;

// Make a request for a user with a given ID
class initWeather {
  constructor() {

    if(!document.querySelector('.content-weather') || !this.canDisplayWeather()) {
      return false;
    }

    disableBodyScroll(document.querySelector('.content-weather'));

    axios.get(`${site.url}/wp-json/mnz-rest/v1/get-weather`).then( response => {
      const weatherBox = document.querySelector('.content-weather');
      const t_min = weatherBox.querySelector('.min');
      const t_max = weatherBox.querySelector('.max');
      const condition = weatherBox.querySelector('.content-weather--condition');
      const condition_icon = weatherBox.querySelector('.content-weather--condition-icon');
      const wind = weatherBox.querySelector('.content-weather--wind .wind');
      
      weatherBox.querySelector('.content-weather--inner').classList.add('loaded');

      condition.innerHTML = response.data.condition.text;
      t_min.innerHTML = `${response.data.min_t}&#x2DA;`;
      t_max.innerHTML = `${response.data.max_t}&#x2DA;`;
      wind.innerHTML = `${response.data.wind_kph} KM/H`;
      condition_icon.innerHTML = `<img src="${response.data.condition.icon}">`;

      const animationKeyFrames = {
          opacity: [1, 0],
        };

      const targetAnimation = weatherBox.animate(animationKeyFrames, {
        delay: 3000,
        duration: 200,
        fill: 'forwards',
        easing: 'ease-in-out'
      });

      weatherBox.querySelector('.content-weather--inner').animate({
         opacity: [1, 0] 
        }, {
          delay: 2600,
          duration: 200,
          fill: 'forwards'
      });

      targetAnimation.onfinish = () => {
        enableBodyScroll(weatherBox);
        weatherBox.style.display = 'none';
        this.disableWeatherWithCookie();
      };

    });

  }

  canDisplayWeather() {
    return Misc.getCookie('hideWeatherSplash') === '1' ? false : true;
  }

  disableWeatherWithCookie() {
    Misc.setCookie('hideWeatherSplash', 1, 1);
  }

}

new initWeather();