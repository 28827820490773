class VideoPlayer {
  /**
   *  OBJECT options: 
   *    item DIV DOMElement
   *    video videojs VIDEO DOMEelement
  **/
  constructor(options) {
    
    this.video = options.video;
    this.item = options.item;
    this.onHoverIn = this.onHoverIn.bind(this);
    this.onHoverOut = this.onHoverOut.bind(this);

    this.item.addEventListener('mouseenter', this.onHoverIn);
    this.item.addEventListener('mouseleave', this.onHoverOut);
    
    this.video.on('playing', () => {
      this.item.querySelector('.icon-loading').style.opacity = '0';
    })

  }

  onHoverIn() {
    this.video.ready( () => {
      this.video.play();
      this.item.querySelector('.block-courses--video-thumbnail').style.opacity = '1';
    });

  }

  onHoverOut() {
    this.item.querySelector('.block-courses--video-thumbnail').style.opacity = '0';
    this.item.querySelector('.icon-loading').removeAttribute('style');
    this.video.pause();
  }

}

export { VideoPlayer };